const app = {
  // baseURL: "https://prod.tas.online/",
  // baseURL: "http://localhost:8000/",
  // baseURL: "https://back.preprod-tas.com/",
  baseURL: "https://production.tas-technologies.com/", // 


  VUE_APP_SECRET_KEY: "TAS_2024@@",
  VUE_APP_MODE: "staging",
  VITE_CUSTOMER_PORTAL_URL: "https://tas-ai-test.customers.subsbase.com/",
  VITE_SITE_ID: "tas-ai-test",

};
//https://back.preprod-tas.com/
//https://prod.tas.online/
//https://production.tas-technologies.com/

export default app;
